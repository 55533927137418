<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Aluminum hydroxide,
        <chemical-latex content="Al(OH)3," />
        can be formed via the reaction:
      </p>

      <p class="mb-5 pl-8">
        <chemical-latex content="Al^3+(aq) + 3 OH^-(aq) -> Al(OH)3(s)" />
      </p>

      <p class="mb-2">
        a) What volume (in mL) of
        <number-value :value="concNaOH" unit="\text{M}" />
        <chemical-latex content="NaOH" />
        is required to completely react with
        <stemble-latex content="$25.00\,\text{mL}$" />
        of
        <number-value :value="concAlCl3" unit="\text{M}" />
        <chemical-latex content="AlCl3?" />
      </p>

      <calculation-input
        v-model="inputs.volNaOH"
        class="mb-4"
        prepend-text="$\text{V}_\text{NaOH}:$"
        append-text="$\text{mL}$"
        :disabled="!allowEditing"
      />

      <p class="mb-n7">
        b) What type of reaction is this?
        <v-select
          v-model="inputs.reactionType"
          :items="items"
          item-text="text"
          item-value="value"
          label="Select Reaction Type"
          class="d-sm-inline-block ml-3 mr-3"
          style="width: 200px"
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>
      </p>

      <p></p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'Question433',
  components: {
    ChemicalLatex,
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        volNaOH: null,
        reactionType: null,
      },
      items: [
        {
          text: 'Acid-Base Neutralization',
          value: 'neutralization',
        },
        {
          text: 'Combustion',
          value: 'combustion',
        },
        {
          text: 'Precipitation',
          value: 'precipitation',
        },
        {
          text: 'Pyrolysis',
          value: 'pyrolysis',
        },
        {
          text: 'Redox',
          value: 'redox',
        },
      ],
    };
  },
  computed: {
    concNaOH() {
      return this.taskState.getValueBySymbol('concNaOH').content;
    },
    concAlCl3() {
      return this.taskState.getValueBySymbol('concAlCl3').content;
    },
  },
};
</script>
